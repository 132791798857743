<template>
  <div v-if="banner">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('banner.create')"
      :title="$t('banner.create')"
      @submitPressed="submitPressed"
    />
    <banner-form
      :banner-obj="banner"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import BannerForm from "@/views/Admin/Banners/components/BannerForm.vue";

export default {
  components: {
    TitleBar,
    BannerForm,
  },
  data() {
    return {
      action: null,
      banner: {
          name: '',
          placement: '',
          slides: [],
          onOff: false
      },
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
