<template>
  <div v-if="banner">
    <b-form @submit.prevent="onSubmit">
      <b-container class="full-w">
        <b-row>
          <b-col cols="12" lg="8">
            <b-card>
              <b-form-group
                :label="$t('banner.name')"
                :class="{ error: v$.banner.name.$errors.length }"
              >
                <b-form-input v-model="banner.name" />
                <div
                  class="input-errors"
                  v-for="error of v$.banner.name.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('banner.placement')"
                label-for="placement"
                :class="{ error: v$.banner.placement.$errors.length }"
              >
                <v-select v-model="banner.placement" :options="placements" />
                <div
                  class="input-errors"
                  v-for="error of v$.banner.placement.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>

              <b-form-group :label="$t('slide.add')">
                <v-select v-model="selectedSlide" :options="availableSlides" />
                <b-button
                  :disabled="!selectedSlide"
                  variant="primary"
                  class="mt-2"
                  @click="addSlide"
                >
                  {{ $t("slide.add") }}
                </b-button>
              </b-form-group>
            </b-card>
          </b-col>

          <b-col cols="12" lg="4">
            <b-card>
              <b-form-group :label="$t('forms.publish')" label-for="published">
                <b-form-checkbox
                  v-model="banner.onOff"
                  name="published"
                  switch
                />
              </b-form-group>

              <b-form-group
                v-if="banner.slides && banner.slides.length"
                :label="$t('banner.order')"
              >
              </b-form-group>
              <draggable
                v-if="banner.slides && banner.slides.length"
                :list="banner.slides"
                item-key="id"
                class="nestable-handle"
              >
                <template #item="{ element }">
                  <div class="nestable-handle-item">
                    <div class="dragg">
                      <bootstrap-icon icon="arrows-move" size="1x" />
                    </div>
                    <b-form-group>
                      <b-form-input v-model="element.name" />
                    </b-form-group>
                    <div class="dragg">
                      <bootstrap-icon
                        @click="removeSlide(element)"
                        icon="trash"
                        size="1x"
                      />
                    </div>
                  </div>
                </template>
              </draggable>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import vSelect from "vue-select";
import draggable from "vuedraggable";
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  props: {
    bannerObj: { type: Object, default() {} },
    action: { type: String, default: null },
  },
  components: {
    draggable,
    vSelect,
  },
  data() {
    return {
      banner: this.bannerObj,
      placements: [],
      availableSlides: [],
      selectedSlide: null,
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
    this.getEnumList("placement", this.placements);
    this.getBannerSlidesList(this.availableSlides);
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      banner: {
        name: { required: this.$translateError('required') },
        placement: { required: this.$translateError('required') },
      },
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    addSlide() {
      const alreadyIn = this.banner.slides.find(
        (banner) => banner["@id"] === this.selectedSlide.id
      );
      if (!alreadyIn) {
        this.banner.slides.push({
          "@id": this.selectedSlide.id,
          name: this.selectedSlide.label,
        });
      }
    },
    removeSlide(slide) {
      this.banner.slides = this.banner.slides
        .map((item) => ({ ...item }))
        .filter((item) => item["@id"] !== slide["@id"]);
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          name: this.banner.name,
          placement: this.banner.placement.id,
          onOff: this.banner.onOff,
          slides: this.banner.slides.map((slide) => slide["@id"]),
          position: this.banner.slides.map((slide) => slide["@id"]),
        };
        if (this.action) {
          this.action === "add"
            ? this.create(
                this.$Banners,
                body,
                this.$t("banner.created"),
                null,
                this.success,
                this.error
              )
            : this.update(
                this.$Banners,
                this.banner.id,
                body,
                this.$t("banner.updated"),
                null,
                null,
                this.error
              );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/banners`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/Nestable.scss";
@import "vue-select/src/scss/vue-select.scss";
</style>
